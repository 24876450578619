html,
body {
  padding: 0;
  margin: 0;
  font-family: "Proxima Nova", Helvetica, Arial, sans-serif;

  box-sizing: border-box;
  font-size: 16px;
  line-height: 150%;
  color: #333;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* more app specific */
.button {
  border: none;
  outline: none;
  border-radius: 16px;
  white-space: nowrap;
  text-decoration: none;
  font-family: system-ui, sans-serif;
  cursor: pointer;
  transition: background-color ease-in 0.1s, box-shadow ease-in 0.1s;
}

.small-button {
  font-size: 16px;
  line-height: 24px;
  padding: 6px 18px;
}

p {
  line-height: 160%;
  font-size: 18px;
  padding: 12px;
  padding-top: 24px;
  color: #444;
  letter-spacing: 0.5px;
}

body {
  background: #eee;
}

.ob-smartfeed-wrapper {
  margin: 4px;
}
